import { Box, Flex, Input, useColorModeValue, Button, InputGroup, InputRightElement, Icon, Text, Alert, AlertIcon, CloseButton } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import {validatePassword} from '../../../../scripts/validations/alterpassword'
import { errorMessages } from 'scripts/utils/errors/AlterError';

interface UserCredentials {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  username: string;
}

const initialCredentials: UserCredentials = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '', 
  username: '' 
};

export default function Banner() {
  const [credentials, setCredentials] = useState<UserCredentials>(initialCredentials);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isError, setIsError] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(false);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const brandStars = useColorModeValue('brand.500', 'brand.200');

  const handleChangePassword = async (event: React.FormEvent) => {
    event.preventDefault();

    const { oldPassword, newPassword, confirmNewPassword, username } = credentials;

    if (!credentials.newPassword || !credentials.oldPassword ||!credentials.confirmNewPassword) {
      setIsError("Preencha todos os campos");
      setLoading(false);
      return;
    }
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
        setIsError(passwordError);
        setLoading(false);
        return;
    }

    if (newPassword !== confirmNewPassword) {
      setIsError('As senhas não coincidem!');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(
        'http://181.215.135.169:5000/user/change-password', 
        {
          username: username,
          password_old: oldPassword,
          password_new: newPassword,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success('Senha alterada com sucesso!');
      setIsError(null);
      setCredentials(initialCredentials);
    } catch (error: any) {
      console.error(error);
      let errorMessage = "Ocorreu um erro inesperado. Tente novamente.";

      if (error.response) {
        if (error.response.status >= 400) {
          const errorKey = error.response.data?.error || "Erro desconhecido";
          errorMessage = errorMessages[errorKey] || errorMessage;
        }
      } else if (error.request) {
        errorMessage = "Não foi possível conectar ao servidor. Verifique sua conexão e tente novamente.";
      } else {
        errorMessage = "Ocorreu um erro inesperado: " + error.message;
      }
      setIsError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems='center' p='20px' marginBottom={5} height='auto'>
      <Box w='100%' h='100%' display='flex' flexDirection='column'>
        <Box color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
          Alterar Senha
        </Box>
        {isError && (
          <Alert status="error" mt={10} borderRadius="md" position="relative">
            <AlertIcon />
            {typeof isError === "string" ? isError : "Ajuste os campos em vermelho."}
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setIsError(false)}
            />
          </Alert>
        )}
        <form onSubmit={handleChangePassword}>
          <Flex direction='column' flex='1' mb='10px' pb="30px" borderRadius='md'>
            <Flex p='15px' mt="40px" bg={box} justify='space-between' mb='10px' alignItems="center">
              <InputGroup>
                <Input
                  color={textColorPrimary}
                  fontSize='md'
                  value={credentials.oldPassword}
                  onChange={(e) => setCredentials({ ...credentials, oldPassword: e.target.value })}
                  variant='unstyled'
                  placeholder='Senha Antiga'
                  type={showOldPassword ? 'text' : 'password'}
                />
                <InputRightElement pb='10px'>
                  <Icon
                    color={brandStars}
                    _hover={{ cursor: "pointer" }}
                    as={showOldPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={toggleShowOldPassword}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Flex p='15px' bg={box} justify='space-between' mb='10px' alignItems="center">
              <InputGroup>
                <Input
                  color={textColorPrimary}
                  fontSize='md'
                  value={credentials.newPassword}
                  onChange={(e) => setCredentials({ ...credentials, newPassword: e.target.value })}
                  variant='unstyled'
                  placeholder='Senha Nova'
                  type={showNewPassword ? 'text' : 'password'}
                />
                <InputRightElement pb='10px'>
                  <Icon
                    color={brandStars}
                    _hover={{ cursor: "pointer" }}
                    as={showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={toggleShowNewPassword}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Flex p='15px' bg={box} justify='space-between' mb='10px' alignItems="center">
              <InputGroup>
                <Input
                  color={textColorPrimary}
                  fontSize='md'
                  value={credentials.confirmNewPassword}
                  onChange={(e) => setCredentials({ ...credentials, confirmNewPassword: e.target.value })}
                  variant='unstyled'
                  placeholder='Confirmar Senha Nova'
                  type={showConfirmNewPassword ? 'text' : 'password'}
                />
                <InputRightElement pb='10px'>
                  <Icon
                    color={brandStars}
                    _hover={{ cursor: "pointer" }}
                    as={showConfirmNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={toggleShowConfirmNewPassword}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Flex>
          <Button width="100%" colorScheme="teal" variant="outline" type="submit" isLoading={loading}>
            Alterar senha
          </Button>
        </form>
      </Box>
    </Card>
  );
}
