import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReportProvider } from 'contexts/ReportContext';

import App from './App';
import { AuthProvider } from 'contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <ReportProvider>
      <BrowserRouter>
        <App />
        <ToastContainer position='top-center' />
      </BrowserRouter>
    </ReportProvider>
  </AuthProvider>,
);
