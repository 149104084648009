import React from 'react';
import { Avatar, Box, Flex, Text, useColorModeValue, Button } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string;
  job: string;
  balance: number | string;
  [x: string]: any;
}) {
  const { banner, avatar, name, job, balance, ...rest } = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue('white !important', '#111C44 !important');

  const handleRedirect = (path: string) => {
  
  const getToken = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    if (!token) {
      token = localStorage.getItem('token');
    }
    return token;
  };

  const redirectTo = (url: string, token: string) => {
    const redirectUrl = new URL(url);
    redirectUrl.searchParams.append('token', token);
    window.location.href = redirectUrl.toString();
  };

  const token = getToken();

  if (token) {
    redirectTo(path, token);
  } else {
    const defaultRedirectUrl = process.env.REACT_APP_DEFAULT_REDIRECT;
    if (defaultRedirectUrl) {
      window.location.href = defaultRedirectUrl;
    } else {
      console.error('Token não foi encontrado e a URL padrão não está definida.');
    }
  }
};


  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" {...rest}>
      <Box bg={`url(${banner})`} bgSize="cover" borderRadius="16px" h="131px" w="100%" />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>

      <Flex w="max-content" mx="auto" mt="20px" alignItems="center" flexDirection="column">
        <Text color={textColorPrimary} fontSize="1xl" fontWeight="700">
          Saldo Total <br />
          <Text color={textColorSecondary} fontSize="sm" textAlign={"center"}>
            R$ {balance}
          </Text>
        </Text>

        <Flex w="100%" mt="20px" justify="space-between" align="center">
          <Button
            colorScheme="green"
            variant="solid"
            flex="1"
            mx="1"
            size="lg"
            minW="150px"
            borderRadius="md"
            onClick={() => handleRedirect(process.env.REACT_APP_REDIRECT_URL_DEPOSIT || '')}
          >
            Depósito
          </Button>
          <Button
            colorScheme="blue"
            variant="solid"
            flex="1"
            mx="1"
            size="lg"
            minW="150px"
            borderRadius="md"
            onClick={() => handleRedirect(process.env.REACT_APP_REDIRECT_URL_WITHDRAWAL || '')}
          >
            Saque
          </Button>
          
        </Flex>
      </Flex>
    </Card>
  );
}