import{ useReport } from "../../../contexts/ReportContext"
import { Box, Grid, Input, Button, Table, Thead, Tbody, Tr, Th, Td, Spinner, Center } from "@chakra-ui/react";
export default function Reports() {
  const { isLoading, tableData, message, setMessage, handleSend } = useReport();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} position="relative">
      {isLoading && (
        <Center
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="#fff"
          zIndex="9999"
          flexDirection="column"
          justifyContent="center"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr 1fr',
        }}
        templateRows={{
          base: 'repeat(3, 1fr)',
          lg: '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        {}
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr',
        }}
        gap="20px"
        alignItems="center"
        mb="40px"
      >
        <Box mt={5} position="relative" width="100%" height="100%" >
          <Input
            placeholder="Faça a sua requisição de relatório."
            bg="white"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            borderRadius="0"
          />
          <Button
            isLoading={isLoading}
            position="absolute"
            top="12"
            right="180"
            colorScheme="blue"
            borderRadius="3"
            onClick={handleSend}
          >
            Carregar
          </Button>
          <Button
            isLoading={isLoading}
            position="absolute"
            top="12"
            right="90"
            colorScheme="blue"
            borderRadius="3"
            onClick={handleSend}
          >
            Salvar
          </Button>
          <Button
            isLoading={isLoading}
            position="absolute"
            top="12"
            right="0"
            colorScheme="blue"
            borderRadius="3"
            onClick={handleSend}
          >
            Enviar
          </Button>
        </Box>
      </Grid>
      <Box mt={12} bg="white" overflowX="auto" overflowY="auto" minHeight={'200px'} display={tableData.header.length > 0 || isLoading ? "inherit" : "none"}>
        {!isLoading && (
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                {tableData.header.map((column, index) => (
                  <Th key={index}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tableData.body.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {tableData.header.map((column, cellIndex) => (
                    <Td key={cellIndex}>{row[column]}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Box>
  );
}