// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Projects(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const userData = localStorage.getItem('userData');
	let jsonUser = {
		'name': '',
		'username': '',
		'wallets': [{
			'value': '0',
			'default': true,
			'name': ''
		}]
	}
	if (userData){
		jsonUser = JSON.parse(userData);
	}

	// Sample wallet data
	
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
			

			{/* Banner content integrated directly */}
			<Card mb={{ base: '0px', lg: '20px' }} alignItems='center' p='20px'>
				<Box mt='20px' w='100%'>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='40px'>
						Carteiras
					</Text>
					{jsonUser.wallets.map((wallet, index) => (
						<Flex key={index} justify='space-between' mb='10px' p='10px' bg={box} borderRadius='md'>
							<Text color={textColorSecondary} fontSize='md'>
								{wallet.name}
							</Text>
							<Text color={textColorPrimary} fontSize='md'>
								R${parseFloat(wallet.value).toFixed(2).replace('.', ',')}
							</Text>
						</Flex>
					))}
				</Box>
			</Card>

			
		</Card>
	);
}
