// Chakra imports
import { Box, Grid } from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/profile/components/Banner';
import Wallets from 'views/admin/profile/components/Wallets';
import DataUser from 'views/admin/profile/components/DataUser';
import AlterPassword from 'views/admin/profile/components/AlterPassword';

// Assets
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/avatar4.png';

export default function Overview() {
	const userData = localStorage.getItem('userData');
	let jsonUser = {
		'name': '',
		'username': '',
		'balance': 0,
		'wallets': [{
			'value': '0',
			'default': true,
			'name': ''
		}]
	}
	if (userData){
		jsonUser = JSON.parse(userData);
		jsonUser.balance = 0;
		jsonUser.wallets.forEach(element => {
			jsonUser.balance += parseFloat(element.value)
		});
	}

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Grid
				templateColumns={{
					base: '1fr',
					lg: '1fr 1fr 1fr'
				}}
				templateRows={{
					base: 'repeat(3, 1fr)',
					lg: '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				<Banner
					gridArea='1 / 1 / 2 / 2'
					banner={banner}
					avatar={avatar}
					name={jsonUser.username}
					job={jsonUser.name}
					balance={jsonUser.balance}
				/>
				<DataUser  />
				<AlterPassword/>
			</Grid>
			<Grid
				mb='20px'
				templateColumns={{
					base: '1fr',
					lg: 'repeat(2, 1fr)',
					'2xl': '1fr'
				}}
				templateRows={{
					base: '1fr',
					lg: '1fr',
					'2xl': '1fr' 
				}}
				gap={{ base: '20px', xl: '20px' }}>
				<Wallets
					gridColumn={{ base: '1 / 2', lg: '1 / 3', '2xl': '1 / 2' }} 
					banner={banner}
					avatar={avatar}
					name={jsonUser.username}
					job={jsonUser.name}
				/>
				
			</Grid>
		</Box>
	);
}
