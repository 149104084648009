// ReportContext.tsx
import React, { createContext, useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ReportContextData } from 'interfaces/dashInterfaces';

const ReportContext = createContext<ReportContextData | undefined>(undefined);

export const ReportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [tableData, setTableData] = useState<{ header: string[], body: any[] }>({ header: [], body: [] });

  const handleSend = () => {
    setIsLoading(true);
    setTableData({ header: [], body: [] });
    postReportRequest(localStorage.getItem('token') || '');
  };

  
  const postReportRequest = async (token: string) => {
    try {
      const body = { message };
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/report/ia`, body, {
        headers: { Authorization: token }
      });
      checkReportStatus(token, response.data.token);
    } catch (error) {
      console.log('erro', error);
      setIsLoading(false);
    }
  };

  const checkReportStatus = (token: string, reportToken: string) => {
    const intervalId = setInterval(async () => {
      try {
        const reportResponse = await getReportRequest(token, reportToken);
        if (reportResponse.status === 200) {
          setIsTableVisible(true);
          setIsLoading(false);
          setTableData(reportResponse.data);
          clearInterval(intervalId);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('Erro ao verificar o status do relatório', error);
          const axiosError = error as AxiosError;
          if (axiosError.code && axiosError.code !== 'ERR_BAD_RESPONSE') {
            return;
          }
        }
        setIsLoading(false);
        clearInterval(intervalId);
      }
    }, 2000);
  };

  const getReportRequest = async (token: string, reportToken: string) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.get(`${apiUrl}/report/ia/${reportToken}`, {
      headers: { Authorization: token }
    });
    return response;
  };

  return (
    <ReportContext.Provider value={{ isLoading, isTableVisible, tableData, message, setMessage, handleSend }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = (): ReportContextData => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReport deve ser usado dentro de um ReportProvider');
  }
  return context;
};
