import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import initialTheme from './theme/theme';
import { useAuth } from './contexts/AuthContext';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [searchParams] = useSearchParams();
  const { fetchUserData, setToken } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const tokenFromSearch = searchParams.get('token');
      let tokenToUse = tokenFromSearch;

      if (!tokenFromSearch) {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          tokenToUse = storedToken;
        } else {
          window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT!;
          return;
        }
      }

      if (tokenToUse && tokenToUse !== localStorage.getItem('token')) {
        localStorage.setItem('token', tokenToUse);
        setToken(tokenToUse);
        await fetchUserData(tokenToUse);
      }
    };

    checkAuth();
  }, [searchParams, fetchUserData, setToken]);

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
