// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Banner() {
  const userData = localStorage.getItem('userData');
  let jsonUser = {
    'name': '',
    'username': '',
    'cpf': '',
    'email': ''
  }
  if (userData){
    jsonUser = JSON.parse(userData);
  }

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems='center' p='20px' height='auto'>
      <Box w='100%'  h='100%' display='flex' flexDirection='column' >
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
          Dados
        </Text>
        <Flex direction='column' mt='30px' flex='1' mb='10px' p='10px' borderRadius='md'>
          <Flex p='15px' bg={box} justify='space-between' mb='10px'>
            <Text color={textColorSecondary} fontSize='md'>
              Username
            </Text>
            <Text color={textColorPrimary} fontSize='md'>
              {jsonUser.username}
            </Text>
          </Flex>
          <Flex p='15px' bg={box} justify='space-between' mb='10px'>
            <Text color={textColorSecondary} fontSize='md'>
              CPF
            </Text>
            <Text color={textColorPrimary} fontSize='md'>
              {jsonUser.cpf}
            </Text>
          </Flex>
          <Flex p='15px' bg={box} justify='space-between'>
            <Text color={textColorSecondary} fontSize='md'>
              Email
            </Text>
            <Text color={textColorPrimary} fontSize='md'>
              {jsonUser.email}
            </Text>
          </Flex>
        </Flex>
        
      </Box>
    </Card>
  );
}
