export type ErrorMessages = {
    PASSWORD_ALREADY_BEING_USED_BY_USER:string;
    PASSWORD_DONT_MATCH:string;
    ERROR_TO_CREATE:string;
    PASSWORD_TOO_SHORT: string,
    PASSWORD_TOO_LONG: string,

};



export const errorMessages: { [key: string]: string } = {
    PASSWORD_ALREADY_BEING_USED_BY_USER: "Insira uma senha nova diferente da atual.",
    PASSWORD_DONT_MATCH: "As senhas não são iguais.",
    ERROR_TO_CREATE: "Ocorreu um erro inesperado, tente novamente mais tarde.",
    PASSWORD_TOO_SHORT: "A senha deve ter no mínimo 8 caracteres.",
    PASSWORD_TOO_LONG: "A senha deve ter no máximo 12 caracteres.",
};