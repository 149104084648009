import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdPsychology,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import Reports from 'views/admin/reports';


// Auth Imports

const routes = [
  {
    name: 'Início',
    layout: '/admin',
    path: '/',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Transações',
    layout: '/admin2', // botei o dois pra sair do menu
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <DataTables />,
  },
  {
    name: 'Relatórios',
    layout: '/admin',
    path: '/relatorios',
    icon: <Icon as={MdPsychology} width="20px" height="20px" color="inherit" />,
    component: <Reports/>
  },
  {
    name: 'Perfil',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
];

export default routes;
