import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContextType } from 'interfaces/dashInterfaces';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);

  const fetchUserData = async (token: string) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/user/me`, {
        headers: {
          Authorization: token
        }
      });
      setUserData(response.data);
      localStorage.setItem('userData', JSON.stringify(response.data));
    } catch (error) {
      window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT!;
    }
  };



  return (
    <AuthContext.Provider value={{ fetchUserData, token, userData, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth deve ser utilizado junto ao AuthProvider');
  }
  return context;
};
